import React,{Component} from "react";
import {ASSETS} from "../../constants/paths";
class LoaderComponent extends Component{
    loaderItem = React.createRef();
    componentDidMount() {
        typeof window !== undefined && document.body.classList.add("no-scroll");
    }
    componentWillUnmount() {
        typeof window !== undefined && document.body.classList.remove("no-scroll");

    }
    render() {
        return(
            <div className="loader-item" ref={this.loaderItem}>
            <div className="video-block">
                <video height={96} width={96} className="video-item" muted playsInline autoPlay loop>
                    <source src={`${ASSETS}/video/loader.webm`} type="video/webm" />
                    <source src={`${ASSETS}/video/loader.mp4`} type="video/mp4" />
                </video>
            </div>
            </div>

        )
    }
}

export default LoaderComponent;