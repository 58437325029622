import React, {Component} from 'react';
import Slider from "react-slick";
import {connect} from "react-redux";
import { ASSETS } from '../constants/paths';
class AwardsImageComponent extends Component {
    componentDidMount() {
    }
    render() {
        const settings = {slidesToShow: 10,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            dotsClass: "slick-dots",
            infinite: false,
            cssEase: 'ease-out',
            focusOnSelect: true,
            centerMode: false,
            speed: 700,
            touchMove: true,
            swipeToSlide: true,
            overrideCenterModeSlideCount: true,
            touchThreshold: 100,
            initialSlide: 0,
            wrapperAlign: true,
            wrapperClass: ".safe-area .awardsroot",
            responsive: [
                {breakpoint: 2500, settings: {slidesToShow: 7,slidesToScroll: 1  } },
                { breakpoint: 1750,settings: {slidesToShow: 6,slidesToScroll: 1 }},
                { breakpoint: 1420,settings: {slidesToShow: 5, slidesToScroll: 1} },
                { breakpoint: 1250, settings: {slidesToShow: 4,slidesToScroll: 1 }},
                { breakpoint: 950, settings: { slidesToShow: 3, slidesToScroll: 1 }},
                { breakpoint: 750,settings: { slidesToShow: 2, slidesToScroll: 1 }}
            ] 
        };
        return (
            <div>
            { this.props.awards &&
            <div>
           <div className="safe-area">
                <div className="awardsroot">
                    <div className="head-inner">
                        <div className="head-inner__image head-inner__image--award">
                            <img src={`${ASSETS}/img/${this.props.awards.img}`} alt="ödül" />
                        </div>
                        <div className="head-inner__text">
                            <h2>{this.props.awards.h2}</h2>
                            <p dangerouslySetInnerHTML={{__html:`${this.props.awards.p}`}}>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Slider {...settings} className="awardsrootslides">
                {
                    this.props.awards.items && this.props.awards.items.map((item,k)=>
                    <div key={k} index={k} className="awardsrootslides__item">
                        <img src={`${ASSETS}/img/${this.props.awards.bg_img}`} className="itembg" alt='ödül arkaplan' />
                        <div className="awardsrootslides__text">
                            <div className="awardsrootslides__image">
                                <img src={`${ASSETS}/img/awards-logo/${item.img}`} alt={item.alt} />
                            </div>
                            <h2 className="awardsrootslides__name" dangerouslySetInnerHTML={{__html:`${item.h2}`}}></h2>
                            <p className="awardsrootslides__number">{item.p}</p>
                        </div>
                    </div>)
                }
            </Slider>
           </div>
    }
    </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    awards: state.awardsReducer[state.LangReducer.lang]&&state.awardsReducer[state.LangReducer.lang] ?state.awardsReducer[state.LangReducer.lang]: null
})
export default connect(mapStateToProps)(AwardsImageComponent);
