import React, {Component} from 'react';
import PageService from "../pageServices/pageService";
import {connect} from "react-redux";
import WhoAreWeWorksComponent from "../components/whoAreWe/whoAreWeWorksComponent";
import AllBrandsComponent from "../components/whoAreWe/allBrandsComponent";
import {getElementPosition} from "../helper";
import LoaderComponent from '../components/layout/loaderComponent';
import AwardsImageComponent from '../components/awardsImageComponent';
class WhoAreWePage extends Component {
    constructor(props) {
        super(props);
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(props.dispatch);
    }

    componentDidMount() {
        if(this.props.location.pathname.indexOf("/markalar")>-1){
            this.scrollToSubLink();
        }
    }
    componentDidUpdate(prevProps) {
        if(this.props !== prevProps){
            if(this.props.location.pathname.indexOf("/markalar")>-1){
                this.scrollToSubLink();
            }
        }

    }

    scrollToSubLink = () => {
        if(typeof window !== "undefined"){
            setTimeout(()=>{
                window.scrollTo({
                    left:0,
                    top:getElementPosition(document.getElementById("brandRoot")).y,
                    behavior: 'smooth'
                })
            },150)
        }
    }
    render() {
        let{bannerText,say_hello}=this.props;
        return (
            <div className="who-we-root">
                {!bannerText && <LoaderComponent/>}
                <div className="who-we-container">
                    {
                        bannerText &&
                        <div className="who-we-text-block">
                            <div className="text-item">
                                <p>{bannerText.desc1}</p>
                                <span>{bannerText.desc2}</span>
                            </div>
                        </div>
                    }
                    <WhoAreWeWorksComponent/>
                    <AwardsImageComponent />
                    <AllBrandsComponent/>
                    <div className="hello-text-block">
                        <div className="hello-text-item">
                            <h2>
                                {say_hello}
                            </h2>
                        </div>
                        <div className="hello-info-item">
                            <a href="mailto:info@kollektif.com.tr"> info@kollektif.com.tr </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    bannerText: state.whoAreWeReducer[state.LangReducer.lang]&&state.whoAreWeReducer[state.LangReducer.lang].bannerText?state.whoAreWeReducer[state.LangReducer.lang].bannerText : null,
    say_hello: state.whoAreWeReducer[state.LangReducer.lang]&&state.whoAreWeReducer[state.LangReducer.lang].say_hello?state.whoAreWeReducer[state.LangReducer.lang].say_hello : null,
})
export default connect(mapStateToProps) (WhoAreWePage);
