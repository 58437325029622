import { combineReducers } from 'redux';
import LangReducer from './langReducer';
import homeReducer from './homeReducer';
import {reducer as formReducer} from 'redux-form';
import LayoutReducer from "./layoutReducer";
import projectReducer from "./projectReducer";
import notFoundReducer from "./notFoundReducer";
import whoAreWeReducer from "./whoAreWeReducer";
import sayHelloReducer from "./sayHelloReducer";
import worksReducer from "./worksReducer";
import cookieReducer from "./cookieReducer";
import kvkkReducer from "./kvkkReducer";
import awardsReducer from './awardsReducer';

const combineRed = combineReducers({
    LangReducer,
    LayoutReducer,
    projectReducer,
    homeReducer,
    worksReducer,
    awardsReducer,
    sayHelloReducer,
    notFoundReducer,
    whoAreWeReducer,
    cookieReducer,
    kvkkReducer,
    form: formReducer,
});

export default combineRed