import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";

class ImageComponent extends Component {
    videoItem = React.createRef();
    render() {
        let {singleImages}=this.props;
        return (
            <div className="selection-block">
                {
                    singleImages.video ?
                        <video className="right-video" muted playsInline autoPlay ref={this.videoItem}
                               //onLoadedMetadata={this.onVideoLoaded}
                               onEnded={() => {
                                   //this.videoItem.current.currentTime = 3;
                                   this.videoItem.current.play();
                               }}>
                            <source
                                src={`${ASSETS}${singleImages.video}`}
                                type="video/webm" />
                            <source
                                src={`${ASSETS}${singleImages.video.substring(0, singleImages.video.lastIndexOf("."))}.mp4`}
                                type="video/mp4" />
                        </video>
                        :
                        <LazyLoad placeholder={<IMG/>}>
                            <picture>
                                <source media="(min-width: 768px)"
                                        //srcSet={`${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_2x.webp 2x`}
                                        srcSet={`${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}.webp 1x`}
                                        type="image/webp"/>
                                <source media="(min-width: 768px)"
                                        //srcSet={`${ASSETS}${singleImages.img} 1x, ${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_2x${singleImages.img.substring(singleImages.img.lastIndexOf("."))} 2x`}
                                        srcSet={`${ASSETS}${singleImages.img} 1x`}
                                        type="image/jpeg"/>

                                <source media="(max-width: 767px)"
                                        srcSet={`${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_mobile_3x.webp 3x`}
                                        type="image/webp"/>
                                <source media="(max-width: 767px)"
                                        srcSet={`${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_mobile${singleImages.img.substring(singleImages.img.lastIndexOf("."))} 1x, ${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_mobile_2x${singleImages.img.substring(singleImages.img.lastIndexOf("."))} 2x, ${ASSETS}${singleImages.img.substring(0, singleImages.img.lastIndexOf("."))}_mobile_3x${singleImages.img.substring(singleImages.img.lastIndexOf("."))} 3x`}
                                        type="image/jpeg"/>

                                {/*<source media="(min-width: 768px)" srcSet={`${ASSETS + singleImages.img} 1x, ${ASSETS + singleImages.img2x} 2x, ${ASSETS + singleImages.img3x} 3x`}  type="image/jpeg" />*/}
                                {/*<source media="(min-width: 768px)" srcSet={`${ASSETS + singleImages.img.substring(0, singleImages.img.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS + singleImages.img2x.substring(0, singleImages.img2x.lastIndexOf(".")) + ".webp"} 2x, ${ASSETS + singleImages.img3x.substring(0, singleImages.img3x.lastIndexOf(".")) + ".webp"} 3x`}  type="image/webp" />*/}
                                {/*<source media="(max-width: 767px)" srcSet={`${ASSETS + singleImages.mobileImg.substring(0, singleImages.mobileImg.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS + singleImages.mobileImg2x.substring(0, singleImages.mobileImg2x.lastIndexOf(".")) + ".webp"} 2x, ${ASSETS + singleImages.mobileImg3x.substring(0, singleImages.mobileImg3x.lastIndexOf(".")) + ".webp"} 3x`}   type="image/webp" />*/}
                                {/*<source  media="(max-width: 767px)"  srcSet={`${ASSETS + singleImages.mobileImg} 1x, ${ASSETS + singleImages.mobileImg2x} 2x, ${ASSETS + singleImages.mobileImg3x} 3x`} type="image/jpeg" />*/}
                                <img src={`${ASSETS + singleImages.img}`} alt={singleImages.alt}/>
                            </picture>
                        </LazyLoad>
                }
            </div>
        );
    }
}
export default ImageComponent;