import React, {Component} from 'react';
import {connect} from "react-redux";
import BrandItemComponent from "./brandItemComponent";

class BrandsComponent extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div className="brand-root">
                <div className="head-block">
                    <div className="head-item" dangerouslySetInnerHTML={{__html:`${this.props.brands_title && this.props.brands_title}`}}>
                        {/* <h3 className="year" >14 Seneye Sığan</h3>
                        <span>Koca Koca Markalar</span> */}
                        
                    </div>
                    <div className="img-item">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="29" height="25" viewBox="0 0 29 25">
                            <defs>
                                <path id="b1qrgd02va" d="M0 0L28.577 0 28.577 23.455 0 23.455z"/>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <g>
                                    <g transform="translate(-378 -3626) translate(378.211 3626.91)">
                                        <mask id="_0jyjy8ra8b" fill="#fff">
                                            <use xlinkHref="#b1qrgd02va"/>
                                        </mask>
                                        <path fill="#EE7427" d="M27.637 11.563c-2.86 7.177-13.349 11.892-13.349 11.892S3.8 18.74.94 11.563C-1.921 4.386 2.232 0 6.933 0c4.509 0 7.355 3.057 7.355 3.057S17.135 0 21.644 0c4.701 0 8.854 4.386 5.993 11.563" mask="url(#_0jyjy8ra8b)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                </div>
                <div className="brand-container">
                    {
                        this.props.brands&& this.props.brands.map((item, key) =>
                            <BrandItemComponent item={item} key={key}/>
                        )
                    }
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    brands: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].brands?state.homeReducer[state.LangReducer.lang].brands : null,
    brands_title: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].brands_title?state.homeReducer[state.LangReducer.lang].brands_title : null
})
export default connect(mapStateToProps)(BrandsComponent);
