import * as ROUTES from '../constants/routePaths';
import {getHomeDataInit} from './homeServices';
import {ENLANG, TRLANG} from "../constants/paths";
import {changeActiveLangAction} from "../actions/index";
import * as webService from "../services/webService";
import * as actionTypes from "../constants/actionTypes";
import Paths from "../helper/path";
import {getProjectDetailDataInit} from "./projectDetailServices";
import {getNotFoundDataInit} from "./notFoundServices";
import {getWhoAreWeDataInit} from "./whoAreWeServices";
import {getWorksDataInit} from "./worksServices";
import {getHelloDataInit} from "./sayHelloServices";
import {getCookieDataInit} from "./cookieServices";
import {getKvkkDataInit} from "./kvkkServices";


const receiveLayOut = (payload) => ({
    type: actionTypes.RECEIVE_LAYOUT,
    payload
});

const receiveLayOutAwards = (payload) => ({
    type: actionTypes.RECEIVE_AWARDS,
    payload
});

export default class PageService {
    currentPage = ROUTES.HOME;

    constructor(url) {
        this.currentPage = url;
        this.checkLang();
    }

    checkLang = () => {
        let locationArr = this.currentPage.split("/");
        if (locationArr[1] === ENLANG) {
            this.pathGenerator(ENLANG);
        }else if(locationArr[1] === TRLANG){
            this.pathGenerator(TRLANG);
        } else{
            this.pathGenerator(TRLANG);
        }
    };

    pathGenerator(lang) {
        this.paths = new Paths(lang, ROUTES.routePaths);
    }

    getData = async (dispatch, urlObj, params = {}) => {
        //console.log("page service esatttt-----");
        return new Promise(async (resolve, reject) => {
            await webService.getLayout()
            .then((res) => {
                if (res.data.success) {
                    dispatch(receiveLayOut(res.data.success));
                }
            });
            await webService.getAwards()
            .then((res) => {
                if (res.data.success) {
                    dispatch(receiveLayOutAwards(res.data.success));
                }
            });
            if (this.paths) {
                switch (this.currentPage) {

                    case this.paths.home:
                        getHomeDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    case this.paths.project_detail.replace(":detailLink", params.detailLink ? params.detailLink : ""):
                        getProjectDetailDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    case this.paths.who_are_we:
                    case this.paths.brands:
                        getWhoAreWeDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    case this.paths.works:
                        getWorksDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    case this.paths.sayhello:
                        getHelloDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    case this.paths.cookie:
                        getCookieDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    case this.paths.kvkk:
                        getKvkkDataInit(dispatch)
                            .then((res) => {
                                resolve(res);
                            });
                        break;
                    default:
                        getNotFoundDataInit(dispatch)
                        .then((res) => {
                            resolve(res);
                        });
                        resolve(false);
                }
            }

        });
    }
}
