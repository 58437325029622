import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";
import {ENLANG, TRLANG} from "../../constants/paths";
import {changeActiveLangAction} from "../../actions";
import {languages} from "../../constants/paths";
import { getLangLink, scrollToTop } from "../../helper";
import modelDetailTween from "../../helper/modelDetailTween";
import * as ROUTES from "../../constants/routePaths";
import Paths from "../../helper/path";
class HeaderComponent extends Component {
    openMenu = React.createRef();
    noScroll = React.createRef();
    logoRef = React.createRef();
    menuRef = React.createRef();
    infoRef = React.createRef();
    paths = null;
    state={
        isSticky:false
    }

    constructor(props) {
        super();
        this.paths = new Paths(props.activeLang, ROUTES.routePaths);
    }
    componentDidMount() {
        if (typeof  window !== "undefined"){
            if(this.logoRef && this.logoRef.current)
                this.logoRef.current.style.opacity=0;
            window.addEventListener('resize', this.resizingWindow);
            
            this.headerTween();
            
           
        }
        window.addEventListener('scroll',this.addStickyClassToHeader)
        window.addEventListener('scroll',this.addStickyClassToHeader)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll',this.addStickyClassToHeader)
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.paths = new Paths(this.props.activeLang, ROUTES.routePaths);
            this.forceUpdate();
        }
    }
    //animasyon
    headerTween = () => {
        setTimeout(() => {
            let ModelDetailTween = new modelDetailTween();
            let logoTween = ModelDetailTween.opacityAnimation(this.logoRef.current,{y:"-1vh"})
            let menuTween = ModelDetailTween.opacityAnimation(this.menuRef.current,{y:"-1vh"})
            let infoTween = ModelDetailTween.opacityAnimation(this.infoRef.current,{y:"-1vh"})
            ModelDetailTween.createTimeLine(null, [logoTween,menuTween,infoTween]); 
        
        },1300)
            
    }
    addStickyClassToHeader = (e) => {
        if(typeof window !== "undefined"){
            if(window.pageYOffset>0 && !this.state.isSticky){
                this.setState({
                    isSticky:true
                })
            }else if(window.pageYOffset===0 && this.state.isSticky){
                this.setState({
                    isSticky:false
                })
            }

        }
    }
    addNoScroll = ()=>{
        document.body.classList.add('no-scroll')
    }
    removeNoScroll = ()=>{
        document.body.classList.remove('no-scroll')
    }
    resizingWindow = () => {
        if (window.innerWidth > 851 && this.openMenu.current){
            this.removeNoScroll();
            this.openMenu.current.classList.remove("opened");
        }
    }
    toggleMenu = () =>{
        if (typeof window !== "undefined" && window.innerWidth < 851){
            this.openMenu.current.classList.toggle("opened");
            document.body.classList.toggle('no-scroll')
        }
        else if (typeof window !== "undefined" && window.innerWidth > 851){
            this.removeNoScroll()
        }

    };
    closeMenu = () =>{
        if (typeof window !== "undefined" && window.innerWidth < 851){
            this.openMenu.current.classList.remove("opened");
            document.body.classList.remove('no-scroll')
        }
        else if (typeof window !== "undefined" && window.innerWidth > 851){
            this.removeNoScroll()
        }

    };
    langChange = () => {

        /*if (this.props.activeLang === ENLANG) {
            this.props.dispatch(changeActiveLangAction(TRLANG));
            //this.props.history.push("/tr");
        } else if (this.props.activeLang === TRLANG) {
            this.props.dispatch(changeActiveLangAction(ENLANG));
            //this.props.history.push("/en");
        }*/
        let {activeLang, location} = this.props;
        let langLink = getLangLink(activeLang, location,activeLang==="tr"?"en":"tr");
        this.langMenu = false;
        this.props.history.push(langLink);
        this.toggleMenu()
    };
    render() {
        let {layoutLang, activeLang} = this.props;
        return (
            <header className={`header-root ${this.state.isSticky ? "sticky": ""}`} >
                <div className="loader-bar"></div>
                <div className="header-container safe-area" ref={this.openMenu}>
                    <div className="header-left-area" ref={this.logoRef}>
                        <Link to={`/` + activeLang} className="logo-block" onClick={scrollToTop}>
                            <picture>
                                <img height={146} width={106}  src={`${ASSETS}/img/kollektif-logo.svg`} alt="logo"/>
                            </picture>
                        </Link>
                    </div>
                    <div className="mobil-btn-item" onClick={this.toggleMenu} ref={this.noScroll}>
                        <i/>
                        <i/>
                    </div>
                    <div className="header-right-area">
                       <div className="mobile-top-block">
                           <div className="header-left-area" >
                               <Link to={`/` + activeLang} className="logo-block">
                                   <picture>
                                       <img height={95} width={69} src={`${ASSETS}/img/kollektif-logo.svg`} alt="logo"/>
                                   </picture>
                               </Link>
                           </div>
                           <div className="mobil-btn-item" onClick={this.toggleMenu} ref={this.noScroll}>
                               <i/>
                               <i/>
                           </div>
                       </div>
                        <div className="categories-block">
                            {layoutLang &&
                            <ul ref={this.menuRef}>
                                <li><Link onClick={this.closeMenu} to={this.paths.works}>{layoutLang.works}</Link></li>
                                <li><Link onClick={this.closeMenu} to={this.paths.who_are_we}>{layoutLang.whoarewe}</Link></li>
                                {/*<li><Link onClick={this.closeMenu} to={this.paths.brands}>{layoutLang.brands}</Link></li>*/}
                            </ul>
                            }
                            <div className="info-block" ref={this.infoRef}>
                                <div className="lang-item" onClick={()=>{this.langChange("tr")}}>
                                    <span>{languages.find(item=>item.value!==this.props.activeLang).label}</span>
                                </div>
                                {/*<Link className="hello-block" href="mailto:info@kollektif.com.tr">*/}
                                <Link onClick={this.closeMenu} className="hello-block" to={this.paths.sayhello}>
                                    <div className="eye-block">
                                        <div className="eyes">
                                            <div className="shut">
                                                <span className="closed"></span>
                                            </div>
                                            <div className="ball">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="hello-item">
                                        <span>{layoutLang && layoutLang.say_hello}</span>
                                    </div>
                                </Link>

                            </div>

                        </div>
                    </div>

                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    layoutLang: state.LayoutReducer[state.LangReducer.lang] ? state.LayoutReducer[state.LangReducer.lang].menu : null
})

export default connect(mapStateToProps)(HeaderComponent);
