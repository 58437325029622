import { RECEIVE_AWARDS} from '../constants/actionTypes'

const initialState = {};


const awardsReducer = (state = initialState, action) => {
    switch(action.type){
        case RECEIVE_AWARDS:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default awardsReducer;