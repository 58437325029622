import axios from 'axios'
import {WEB_SERVICE,API_URL} from "../constants/paths";

export const getLayout = (lang) => {
    return axios.get(`${WEB_SERVICE}layout.json`)
}
export const getHome = () => {
    return axios.get(`${WEB_SERVICE}home.json`)
}
export const getProjects = () => {
    return axios.get(`${WEB_SERVICE}projects.json`)
}
export const getWhoAreWe = () => {
    return axios.get(`${WEB_SERVICE}whoAreWe.json`)
}
export const getWorks = () => {
    return axios.get(`${WEB_SERVICE}works.json`)
}
export const getAwards = () => {
    return axios.get(`${WEB_SERVICE}awards.json`)
}
export const getHello = () => {
    return axios.get(`${WEB_SERVICE}sayHello.json`)
}
export const getCookie = () => {
    return axios.get(`${WEB_SERVICE}cookie.json`)
}
export const getKvkk = () => {
    return axios.get(`${WEB_SERVICE}kvkk.json`)
}
export const getNotFound = () => {
    return axios.get(`${WEB_SERVICE}notFound.json`);
};
export const submitForm = (params) => {
    return axios.post(`${API_URL}contact/addForm`,"params="+JSON.stringify(params));
};
export const saveFile = (CV) => {
    let data = new FormData();
    data.append("file",CV);
    return axios.post(`${API_URL}contact/saveCV`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};