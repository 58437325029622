export const ACTIVE = "ACTIVE";
export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_TAG = "CHANGE_TAG";
export const RECEIVE_LAYOUT = "RECEIVE_LAYOUT";
export const RECEIVE_HOME = "RECEIVE_HOME";
export const RECEIVE_PROJECTS = "RECEIVE_PROJECTS";
export const RECEIVE_NOT_FOUND = "RECEIVE_NOT_FOUND";
export const RECEIVE_WHO_ARE_WE = "RECEIVE_WHO_ARE_WE";
export const RECEIVE_SAYHELLO = "RECEIVE_SAYHELLO";
export const RECEIVE_WORKS = "RECEIVE_WORKS";
export const RECEIVE_COOKIE = "RECEIVE_COOKIE";
export const RECEIVE_KVKK = "RECEIVE_KVKK";
export const RECEIVE_AWARDS = "RECEIVE_AWARDS";
