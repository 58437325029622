import React, {Component} from 'react';
import BrandItemComponent from "../home/brandItemComponent";
import {connect} from "react-redux";

class AllBrandsComponent extends Component {
    render() {
        return (
            <div id="brandRoot" className="brand-block">
                <div className="brand-img-block">
                    <div className="img-item">
                        <picture>
                            <img src="/assets/img/who-we/projeyneticisi.png" alt="products"/>
                        </picture>
                    </div>

                    {
                        this.props.brandText &&
                        <div className="brand-text-item">
                            <h2>{this.props.brandText.title}</h2>
                            <p>{this.props.brandText.desc}</p>
                        </div>
                    }

                </div>
                <div className="brand-icon-block">

                    {
                        this.props.brands&&this.props.home_brands&& this.props.brands.map((item, key) =>
                            <BrandItemComponent key={key} item={item} isGray={ !this.props.home_brands.includes(item)}/>
                        )
                    }
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    home_brands: state.whoAreWeReducer[state.LangReducer.lang]&&state.whoAreWeReducer[state.LangReducer.lang].homeBrands?state.whoAreWeReducer[state.LangReducer.lang].homeBrands : null,
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    brands: state.whoAreWeReducer[state.LangReducer.lang]&&state.whoAreWeReducer[state.LangReducer.lang].brands?state.whoAreWeReducer[state.LangReducer.lang].brands : null,
    brandText: state.whoAreWeReducer[state.LangReducer.lang]&&state.whoAreWeReducer[state.LangReducer.lang].brandText?state.whoAreWeReducer[state.LangReducer.lang].brandText : null
})
export default connect(mapStateToProps)(AllBrandsComponent);